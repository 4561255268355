import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAsyncResult } from 'react-use-async-result';
import { useStore, useStoreDispatch } from '../context';
import { PatientService } from '../services/patient';
import './patient-reports.scss';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { ErrorFlagsModal, GenerateReport } from '../components/generateReport';
import { TFunc, Trans, useTranslation } from '../i18n';
import '../layout/layout.scss';
import { useQueries, useQuery } from '@tanstack/react-query';
import { reportingApi } from '../api';
import { PatientReportResponse, SummaryStatementResponse, SummaryStatementRowResponse } from '../api/generated/models';
import { LoadingSpinner } from '../components/loadingSpinner';

const PdfViewer = (props: { reportId: number }) => {
  const pdfRes = useAsyncResult<any>();
  const patientService = PatientService();

  useEffect(() => {
    pdfRes.clear();
    if (!props.reportId) {
      return;
    }

    pdfRes.bind(patientService.getPatientReportPdf(props.reportId));

    return () => {
      console.log('revoking object url');
      pdfRes.isDone && URL.revokeObjectURL(pdfRes.result);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reportId]);

  if (pdfRes.isError) {
    return (
      <div style={{ color: 'red' }}>
        <Trans>Unable to load PDF file</Trans>: {'' + pdfRes.error}
      </div>
    );
  }

  if (!pdfRes.isDone) {
    return (
      <div>
        <Trans>Loading...</Trans>
      </div>
    );
  }

  return <object data={pdfRes.result} className="pdfViewer" type="application/pdf" />;
};

const usePatientReports = (
  patientId: number,
  patientClinicHospitalPairs: Array<[number, number]> | undefined,
  setAllPatientReports: (reports: PatientReportResponse[]) => void,
) => {
  const patientService = PatientService();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchAllPatientReports = async () => {
    if (!patientClinicHospitalPairs?.length) {
      return;
    }
    setIsLoading(true);
    setIsError(false);
    try {
      const reportsArr: PatientReportResponse[] = [];
      await Promise.all(patientClinicHospitalPairs.map(async ([clinicId, hospitalId]) => {
        if (!clinicId) {
          return;
        }

        const reports = await patientService.getAllPatientReports(patientId, hospitalId, clinicId);
        if (!reports) {
          console.log('no reports');
          return;
        }
        reportsArr.push(...reports);
      }));

      reportsArr.sort((a, b) => moment(b.created_time).diff(moment(a.created_time)));
      setAllPatientReports(reportsArr);
    } catch (err) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchAllPatientReports,
    isLoading,
    isError,
  };
};

const Reports = (props) => {
  const { patient, clinician, allReports } = useStore();
  const { setAllPatientReports } = useStoreDispatch();
  const patientService = PatientService();
  const [expandedReports, setExpandedReports] = React.useState({});
  const clinicianGenReportsAvailable = React.useRef(false);
  const rxfoodGenReportsAvailable = React.useRef(false);
  const sentReportsAvailable = React.useRef(false);
  const [showReportGen, setReportGen] = useState(false);
  const [showErrorFlags, setShowErrorFlags] = useState(false);
  const [errorFlagReport, setErrorFlagReport] = useState<PatientReportResponse>(null);
  const [clinicId, setClinicId] = useState(0);
  const [hospitalId, setHospitalId] = useState(0);
  const [draftPending, setDraftPending] = useState(false);
  const [showClinicalSummaries, setShowClinicalSummaries] = useState({});
  const { t } = useTranslation();

  const patientClinicReq = useQuery(['patient-clinics-req', patient.patient_id, clinician.id], async () => {
    return patientService.getPatientClinics(patient.patient_id);
  });

  // filters out clinics that the clinician is not associated with
  const patientClinicHospitalPairs: Array<[patientClinicId: number, hospitalId: number]> | undefined = patientClinicReq
    .data
    ?.map(
      (patientClinic) => {
        if (clinician.clinics.some(clinicianClinic => clinicianClinic.id === patientClinic.id)) {
          return [patientClinic.id, patientClinic.hospital_id];
        }
        return undefined;
      },
    )
    ?.filter(Boolean);

  const patientReportsQuery = usePatientReports(patient.patient_id, patientClinicHospitalPairs, setAllPatientReports);

  useEffect(() => {
    patientReportsQuery?.fetchAllPatientReports();
    setExpandedReports({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.patient_id, patientClinicHospitalPairs?.length]);

  useEffect(() => {
    if (patientClinicHospitalPairs?.[0]) {
      setClinicId(patientClinicHospitalPairs[0][0]);
      setHospitalId(patientClinicHospitalPairs[0][1]);
    }
  }, [patientClinicHospitalPairs]);

  function setPdfViewer(id, open) {
    const newDict = {};
    for (const x of allReports) {
      if (x.patient_report_id == id) {
        newDict[id] = open;
      } else {
        newDict[x.patient_report_id] = false;
      }
    }
    setExpandedReports(newDict);
  }

  const reportTypeDisplayText = {
    'default': t('Report'),
    'comparison_report': t('Comparison Report'),
    'cgm_report': t('CGM Report'),
    'rxfood': t('RxFood Report'),
  };

  clinicianGenReportsAvailable.current = false;
  rxfoodGenReportsAvailable.current = false;
  sentReportsAvailable.current = false;
  for (const r of allReports) {
    if (r.report_type in reportTypeDisplayText && r.status == 'draft' && r.created_by_user_id == clinician.id) {
      clinicianGenReportsAvailable.current = true;
    } else if (
      r.report_type in reportTypeDisplayText && r.status == 'draft' && r.created_by_user_id !== clinician.id
    ) {
      rxfoodGenReportsAvailable.current = true;
    } else if (r.report_type in reportTypeDisplayText && r.status == 'sent') {
      sentReportsAvailable.current = true;
    }
  }

  const reportQueries = useQueries({
    queries: allReports.map((item, idx) => {
      return {
        queryKey: ['report-summary', item.patient_report_id, patient.patient_id, clinician.id],
        queryFn: async () => {
          const reportData = await reportingApi.appApiPatientReportsApiGetPatientReportData({
            patient_id: item.patient_id,
            hospital_id: item.hospital_id,
            clinic_id: item.clinic_id,
            patient_report_id: item.patient_report_id,
          });
          return reportData.data;
        },
      };
    }),
  });
  const reportClinicalSummary = reportQueries.map(q => q.data?.clinical_summary_statement);

  const errorMessageRegex = /custom|awaiting verification|with more than/;

  const processSendReport = (report: PatientReportResponse) => {
    const isErrorFlag = report.error_flags.find(e => errorMessageRegex.test(e.message));
    if (isErrorFlag) {
      setShowErrorFlags(true);
      setErrorFlagReport(report);
    } else {
      patientService.setReportSent(
        hospitalId,
        clinicId,
        report.patient_id,
        report.patient_report_id,
      );
      patientReportsQuery?.fetchAllPatientReports();
    }
  };

  return (
    <div>
      <div style={{ marginBottom: '100px' }}>
        <br />
        <div className="sectionHeader">
          <div>
            <p className="goalDetail">
              <Trans>Patient Reports</Trans>
            </p>
            <div>
              <Trans>Latest reports first, click to view PDF</Trans>
            </div>
          </div>
          {(clinician.is_report_admin || clinician.is_report_previewer
            || clinician?.flags?.clinician_can_generate_reports) && (
            <Button onClick={() => setReportGen(true)}>
              <Trans>Generate New Report</Trans>
            </Button>
          )}
        </div>

        <div className="mt-4 mb-1" style={{ fontSize: '14px' }}>
          <Trans>
            If a report is missing you can contact support (
            {/**/}
            <a
              href="mailto:support@rxfood.co?bcc=dlichti@rxfood.co&subject=Clinician Portal Report Missing"
              target="_blank"
              className="emailLink"
            >
              support@rxfood.co<i className="fas fa-external-link" />
            </a>
            {/**/}
            ). If it is urgent (i.e. needed during the current day) you can also call 1-888-998-6181.'
          </Trans>
        </div>

        {patientReportsQuery.isError || patientClinicReq.isError
          ? (
            <div>
              <h6>
                <Trans>Error loading patient reports</Trans>
              </h6>
            </div>
          )
          : patientReportsQuery.isLoading
          ? (
            <div>
              <LoadingSpinner />
            </div>
          )
          : (
            <>
              {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                <div>
                  <br />
                  <h6>
                    <Trans>Clinician Generated Reports</Trans>
                  </h6>
                  {draftPending && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        width: 400,
                        color: 'grey.500',
                        margin: 10,
                        gap: 10,
                      }}
                    >
                      <CircularProgress
                        size={20}
                        thickness={5}
                      />
                      <div>
                        <Trans>Generating Report...</Trans>
                      </div>
                    </div>
                  )}
                  {!clinicianGenReportsAvailable.current && (
                    <div>
                      <Trans>No clinician generated reports available.</Trans>
                    </div>
                  )}
                  {[...allReports].map((item, idx) => {
                    const pdfOpened = expandedReports[item.patient_report_id];
                    const hoverText = t('Report Created {{createdTime}}; Updated {{updatedTime}}', {
                      createdTime: moment(item.created_time).format('YYYY-MM-DD HH:MM'),
                      updatedTime: moment(item.updated_time).format('YYYY-MM-DD HH:MM'),
                    });
                    if (
                      item.report_type in reportTypeDisplayText && item.status == 'draft'
                      && item.created_by_user_id == clinician.id
                    ) {
                      return (
                        <div
                          key={idx}
                          id={'' + item.patient_report_id}
                          style={{ paddingBottom: '0.5rem', cursor: 'pointer' }}
                        >
                          <label
                            onClick={() => {
                              setPdfViewer(item.patient_report_id, !pdfOpened);
                            }}
                            title={hoverText}
                            // eslint-disable-next-line i18next/no-literal-string
                          >
                            [{pdfOpened
                              ? '-'
                              // eslint-disable-next-line i18next/no-literal-string
                              : '+'}]{' '}
                            <span className="reportBtnLink">
                              {reportTypeDisplayText[item.report_type]} - {moment(item.date_since).format('YYYY-MM-DD')}
                              {' '}
                              <Trans>to</Trans> {moment(item.date_until).format('YYYY-MM-DD')}
                            </span>
                          </label>
                          {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                            <i
                              className="fas fa-envelope"
                              style={{ width: '36px', paddingLeft: '12px', color: '#909090' }}
                              onClick={() => processSendReport(item)}
                              title={t('Send report')}
                            />
                          )}
                          {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                            <i
                              className="fas fa-redo"
                              style={{ width: '36px', paddingLeft: '6px', color: '#909090' }}
                              onClick={() => patientService.regenerateReport(item.patient_report_id)}
                              title={t('Regenerate report')}
                            />
                          )}
                          {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                            <i
                              className="fas fa-trash-alt"
                              style={{ width: '36px', paddingLeft: '3px', color: '#909090' }}
                              onClick={() => {
                                patientService.deleteReport(
                                  hospitalId,
                                  clinicId,
                                  patient.patient_id,
                                  item.patient_report_id,
                                );
                                setAllPatientReports(
                                  allReports.filter((r) => r.patient_report_id !== item.patient_report_id),
                                );
                              }}
                              title={t('Delete report')}
                            />
                          )}
                          {!!reportClinicalSummary?.[idx] && (
                            <button
                              onClick={() => setShowClinicalSummaries((prev) => ({ ...prev, [idx]: !prev[idx] }))}
                            >
                              <Trans>Clinical Summary</Trans>
                            </button>
                          )}
                          {!!item.dates_exclude?.length && (
                            <div style={{ marginTop: '-5px', marginLeft: '25px', fontSize: '12px' }}>
                              {' '}
                              <Trans>excluding</Trans>{'  '}{item.dates_exclude.map((date, idx) => (
                                <span key={idx}>
                                  {moment(date).format('YYYY-MM-DD')}
                                  {idx < item.dates_exclude.length - 1 ? ', ' : ''}
                                </span>
                              ))}
                            </div>
                          )}
                          {showClinicalSummaries[idx] && (
                            <ClinicalSummaryText
                              reportClinicalSummary={reportClinicalSummary}
                              t={t}
                              idx={idx}
                            />
                          )}
                          {pdfOpened && <PdfViewer reportId={item.patient_report_id} />}
                        </div>
                      );
                    }
                  })}
                </div>
              )}
              <div>
                <br />
                <h6>
                  <Trans>RxFood Generated Reports</Trans>
                </h6>
                {!rxfoodGenReportsAvailable.current
                  && (
                    <div>
                      <Trans>No RxFood generated reports available.</Trans>
                    </div>
                  )}
                {[...allReports].map((item, idx) => {
                  const pdfOpened = expandedReports[item.patient_report_id];
                  const hoverText = t('Report Created {{createdTime}}; Updated {{updatedTime}}', {
                    createdTime: moment(item.created_time).format('YYYY-MM-DD HH:MM'),
                    updatedTime: moment(item.updated_time).format('YYYY-MM-DD HH:MM'),
                  });
                  if (
                    item.report_type in reportTypeDisplayText && item.status == 'draft'
                    && item.created_by_user_id !== clinician.id
                  ) {
                    return (
                      <div
                        key={idx}
                        id={'' + item.patient_report_id}
                        style={{ paddingBottom: '0.5rem', cursor: 'pointer' }}
                      >
                        <label
                          onClick={() => {
                            setPdfViewer(item.patient_report_id, !pdfOpened);
                          }}
                          title={hoverText}
                          // eslint-disable-next-line i18next/no-literal-string
                        >
                          [{pdfOpened
                            ? '-'
                            // eslint-disable-next-line i18next/no-literal-string
                            : '+'}]{' '}
                          <span className="reportBtnLink">
                            {reportTypeDisplayText[item.report_type]} - {moment(item.date_since).format('YYYY-MM-DD')}
                            {' '}
                            <Trans>to</Trans> {moment(item.date_until).format('YYYY-MM-DD')}
                          </span>
                        </label>
                        {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                          <i
                            className="fas fa-envelope"
                            style={{ width: '36px', paddingLeft: '12px', color: '#909090' }}
                            onClick={() => {
                              patientService.setReportSent(
                                hospitalId,
                                clinicId,
                                patient.patient_id,
                                item.patient_report_id,
                              );
                              patientReportsQuery?.fetchAllPatientReports();
                            }}
                            title={t('Send report')}
                          />
                        )}
                        {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                          <i
                            className="fas fa-redo"
                            style={{ width: '36px', paddingLeft: '6px', color: '#909090' }}
                            onClick={() => patientService.regenerateReport(item.patient_report_id)}
                            title={t('Regenerate report')}
                          />
                        )}
                        {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                          <i
                            className="fas fa-trash-alt"
                            style={{ width: '36px', paddingLeft: '3px', color: '#909090' }}
                            onClick={() => {
                              patientService.deleteReport(
                                hospitalId,
                                clinicId,
                                patient.patient_id,
                                item.patient_report_id,
                              );
                              setAllPatientReports(
                                allReports.filter((r) => r.patient_report_id !== item.patient_report_id),
                              );
                            }}
                            title={t('Delete report')}
                          />
                        )}
                        {!!reportClinicalSummary?.[idx] && (
                          <button
                            onClick={() => setShowClinicalSummaries((prev) => ({ ...prev, [idx]: !prev[idx] }))}
                          >
                            <Trans>Clinical Summary</Trans>
                          </button>
                        )}
                        {showClinicalSummaries[idx] && (
                          <ClinicalSummaryText
                            reportClinicalSummary={reportClinicalSummary}
                            t={t}
                            idx={idx}
                          />
                        )}
                        {pdfOpened && <PdfViewer reportId={item.patient_report_id} />}
                        {!!item.dates_exclude?.length && (
                          <div style={{ marginTop: '-5px', marginLeft: '25px', fontSize: '12px' }}>
                            {' '}
                            <Trans>excluding</Trans>{'  '}{item.dates_exclude.map((date, idx) => (
                              <span key={idx}>
                                {moment(date).format('YYYY-MM-DD')}
                                {idx < item.dates_exclude.length - 1 ? ', ' : ''}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
              </div>

              <br />
              <h6>
                <Trans>Sent Reports</Trans>
              </h6>
              {!sentReportsAvailable.current && (
                <div>
                  <Trans>No sent reports available.</Trans>
                </div>
              )}

              {[...allReports].map((item, idx) => {
                const pdfOpened = expandedReports[item.patient_report_id];
                const hoverText = t('Report Created {{createdTime}}; Updated {{updatedTime}}', {
                  createdTime: moment(item.created_time).format('YYYY-MM-DD HH:MM'),
                  updatedTime: moment(item.updated_time).format('YYYY-MM-DD HH:MM'),
                });
                if (item.report_type in reportTypeDisplayText && item.status == 'sent') {
                  return (
                    <div
                      key={idx}
                      id={'' + item.patient_report_id}
                      style={{ paddingBottom: '0.5rem', cursor: 'pointer' }}
                    >
                      <label
                        onClick={() => {
                          setPdfViewer(item.patient_report_id, !pdfOpened);
                        }}
                        title={hoverText}
                        // eslint-disable-next-line i18next/no-literal-string
                      >
                        [{pdfOpened
                          ? '-'
                          // eslint-disable-next-line i18next/no-literal-string
                          : '+'}]{' '}
                        <span className="reportBtnLink">
                          {reportTypeDisplayText[item.report_type]} - {moment(item.date_since).format('YYYY-MM-DD')}
                          {' '}
                          <Trans>to</Trans> {moment(item.date_until).format('YYYY-MM-DD')}
                        </span>
                      </label>
                      {!!reportClinicalSummary?.[idx] && (
                        <button
                          style={{ marginLeft: '10px' }}
                          onClick={() => setShowClinicalSummaries((prev) => ({ ...prev, [idx]: !prev[idx] }))}
                        >
                          <Trans>Clinical Summary</Trans>
                        </button>
                      )}
                      {showClinicalSummaries[idx] && (
                        <ClinicalSummaryText
                          reportClinicalSummary={reportClinicalSummary}
                          t={t}
                          idx={idx}
                        />
                      )}
                      {pdfOpened && <PdfViewer reportId={item.patient_report_id} />}
                      {!!item.dates_exclude?.length && (
                        <div style={{ marginTop: '-5px', marginLeft: '25px', fontSize: '12px' }}>
                          {' '}
                          <Trans>excluding</Trans>{'  '}{item.dates_exclude.map((date, idx) => (
                            <span key={idx}>
                              {moment(date).format('YYYY-MM-DD')}
                              {idx < item.dates_exclude.length - 1 ? ', ' : ''}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </>
          )}
      </div>

      <GenerateReport
        show={showReportGen}
        toggle={() => {
          setReportGen(!showReportGen);
        }}
        draftPending={draftPending}
        setDraftPending={setDraftPending}
      />
      {errorFlagReport && (
        <ErrorFlagsModal
          show={showErrorFlags}
          toggle={() => setShowErrorFlags(!showErrorFlags)}
          report={errorFlagReport}
          errorMessageRegex={errorMessageRegex}
          fetchReports={patientReportsQuery?.fetchAllPatientReports}
        />
      )}
    </div>
  );
};

const ClinicalSummaryText = (props: {
  reportClinicalSummary: SummaryStatementResponse[],
  t: TFunc,
  idx: number,
}) => {
  const { reportClinicalSummary, t, idx } = props;
  const textRef = useRef(null);
  const copyRes = useAsyncResult();
  const copyToClipboard = () => {
    if (textRef.current) {
      const htmlContent = generateHtmlContent(textRef.current);
      const textContent = generateTextContent(textRef.current);
      copyRes.bind(navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([htmlContent], { type: 'text/html' }),
          'text/plain': new Blob([textContent], { type: 'text/plain' }),
        }),
      ]));
    }
  };
  return (
    <div style={{ backgroundColor: '#F1F1F1', padding: '20px 50px 20px 30px', maxWidth: '900px' }}>
      <div style={{ cursor: 'pointer', float: 'right' }}>
        <Tooltip placement="top" title={copyRes.isDone ? t('Copied!') : t('Copy to clipboard')}>
          <i
            className="fas fa-copy"
            style={{ width: '36px', paddingLeft: '3px', color: '#909090' }}
            onClick={copyToClipboard}
          />
        </Tooltip>
      </div>
      <div ref={textRef}>
        <div>
          <Trans>Clinical Summary</Trans>
        </div>
        {formatClinicalSummary(reportClinicalSummary[idx]?.results, t)?.map((s, idx) => (
          <div key={idx} style={{ fontSize: '14px' }}>
            <div style={{ fontWeight: 600 }}>
              {t(s.label)}
            </div>
            <ul>{s.list}</ul>
          </div>
        ))}
      </div>
    </div>
  );
};

const formatListItem = (text: string, idx: number) => {
  if (!text) {
    return null;
  }
  const [firstWord, ...restOfStr] = text.split(' ');
  const formattedRestOfStr = restOfStr.join(' ').replace(/[\[\]]/g, '');
  return (
    <li key={idx}>
      <span style={{ fontWeight: 600 }}>{firstWord}</span>
      {' ' + formattedRestOfStr}
    </li>
  );
};
const formatClinicalSummary = (results: SummaryStatementRowResponse[], t: TFunc) => {
  const energy = results
    ?.find(s => s.sub_category === 'unhealty_fat_kcal')
    ?.text?.split(/(?=was)|(?=et trans)/)
    ?.map((s, idx) => (idx === 0 ? <span key={idx} style={{ fontWeight: 600 }}>{s.toLowerCase()}</span> : s));
  const macroDA = results
    ?.find(s => s.sub_category === 'by_type')
    ?.text;
  const formattedMacroDA = macroDA
    ?.split(/[:,]/)
    ?.map((s, idx) => (idx === 0 ? null : formatListItem(s?.trim(), idx)))
    ?.concat(<li key="energy">{energy}</li>);
  const microDA = results
    ?.filter(s => s.category === 'rdi_summary')
    ?.map(s => s.text?.split(/\bAverage\b|\bApport moyen\b/)[1]?.trim());
  const formattedMicroDA = microDA?.map((s, idx) => formatListItem(s, idx));
  const totalItems = results
    ?.find(s => s.sub_category === 'protein' && s.text.startsWith(t('A total')))
    ?.text?.split(/\.\s/);
  const frequenciesTitle = totalItems?.[0].endsWith('.') ? totalItems?.[0] : totalItems?.[0].concat('. ');
  const beverages = totalItems?.slice(1);
  const frequencies = results
    ?.filter(s => (
      (s.sub_category === 'protein'
        && !s.text.startsWith(t('A total')))
      || (s.sub_category === 'fried_food_freq')
    ))
    ?.map(s => s.text?.split(/\.\s/));
  const frequenciesBeverages = beverages ? frequencies.concat([beverages]) : frequencies;

  const formattedFrequencies = frequenciesBeverages?.map((s, idx) => {
    const firstStr = s[0]?.split(/\,\s/)?.[1] || s[0];
    const firstSentence = firstStr?.endsWith('.') ? firstStr : firstStr?.concat('. ');
    const restOfSentence = s.slice(1);
    return (
      <li key={idx}>
        <span style={{ fontWeight: 600 }}>{firstSentence}</span>
        {restOfSentence && restOfSentence.map(s => s)}
      </li>
    );
  });

  return [
    { label: t('Macronutrients (daily avg.)'), list: formattedMacroDA },
    { label: t('Micronutrients (daily avg.)'), list: formattedMicroDA },
    { label: frequenciesTitle, list: formattedFrequencies },
  ];
};

const generateHtmlContent = (element) => {
  const wrapper = document.createElement('div');
  wrapper.appendChild(element.cloneNode(true));
  // eslint-disable-next-line i18next/no-literal-string
  const elementsToRemoveAttributes = wrapper.querySelectorAll('*[class], *[id], *[style]');
  elementsToRemoveAttributes.forEach((el) => {
    el.removeAttribute('class');
    el.removeAttribute('id');
    el.removeAttribute('style');
  });
  return wrapper.innerHTML;
};

const generateTextContent = (element) => {
  const formattedText = element.innerText
    .replace(/<\/?(?:div|p|span|strong|b|em|i|ul)>/g, '')
    .replace(/<li>/g, '- ')
    .replace(/<\/li>/g, '\n');
  return formattedText;
};

export default Reports;
